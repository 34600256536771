import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import AppHeader from "../header/AppHeader";
import "./DestinationView.scss";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import { BedynamicVariables } from "../../generated/Bedynamic";
import { Destinations_GQL } from "../../queries/AttractionQuery";
import { LoadingResults } from "../common/LoadingResults";
import TopAttractions from "./TopAttractions";
import TopRestaurants from "./TopRestaurants";
import { AttractionFilter } from "../../generated/globalTypes";
import { BedynamicDestinations_attractions_items, BedynamicDestinations_attractions_items_images } from "../../generated/BedynamicDestinations";
import { faMonument, faUtensils, IconPrefix, faLandmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from '@fortawesome/free-brands-svg-icons';

const DestinationView = (props: any) => {
    const { loading, attractions }: any = props;
    const { city, state, country, contentId } = props.match.params;

    if (loading && (!attractions || attractions === undefined)) return <Container className="pb-5">
        <Row>
            <Col md={12}>
                <AppHeader />
            </Col>
        </Row>
        <Row>
            <LoadingResults />
        </Row>
    </Container>;

    const attraction: BedynamicDestinations_attractions_items = attractions.items[0];

    const navigateToDetails = () => props.history.push({
        pathname: `/view/${country.toLowerCase()}/${state.toLowerCase()}/${city.toLowerCase().trim().replace(/\s/g, "_")}/${contentId}`
    });

    const navigateToDetailsUrl = (attraction: BedynamicDestinations_attractions_items) => `/view/${country.toLowerCase()}/${state.toLowerCase()}/${city.toLowerCase().replace(/\s/g, '_')}/${contentId}`;
    return (
        <Container className="pb-5">
            <Row>
                <Col md={12}>
                    <AppHeader />
                </Col>
            </Row>
            <Row style={{ marginTop: '2%' }}>
                <Col md={12}>
                    <h4>{attraction.name}, {attraction.address.country}</h4>
                </Col>
            </Row>
            <Row className="mt-3 text-center">
                <Col md={12}>
                    <Carousel>
                        {
                            attraction.images.filter( x => x.imageTypeText != "Thumbnail").map((image: BedynamicDestinations_attractions_items_images, index: number) => (
                                <Carousel.Item key={index}>
                                    <img onClick={() => navigateToDetails()}
                                        className="d-block w-100"
                                        src={image.url}
                                        alt={attraction.name}
                                    />
                                    <Carousel.Caption>
                                        <h2>{attraction.name}</h2>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={12}>
                    <p className="text-justify" dangerouslySetInnerHTML={{ __html: attraction.description }}></p>
                </Col>
            </Row>
            <Row className="mt-2 headBackgroundColor">
                <Col md={12}>
                    <div>
                        <h5 className="float-left"><FontAwesomeIcon icon={faLandmark} className="fontIconColorPrimary" />&nbsp;Top Attractions</h5>
                        <a className="float-right seeAll" href={navigateToDetailsUrl(attraction)}>See All Attractions</a>
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={12}>
                    <TopAttractions params={props.match.params} history={props.history} />
                </Col>
            </Row>
            <Row className="mt-2 headBackgroundColor">
                <Col md={12}>
                    <div>
                        <h5 className="float-left"><FontAwesomeIcon icon={faUtensils} className="fontIconColorPrimary" />&nbsp;Top Restaurants</h5>
                        <a className="float-right seeAll" href={navigateToDetailsUrl(attraction)}>See All Restaurants</a>
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={12}>
                    <TopRestaurants params={props.match.params} history={props.history} />
                </Col>
            </Row>
        </Container >
    )
}

const mapResultsToProps = ({ data }: any) => {
    if (!data.attractions)
        return {
            loading: data.loading,
        };

    return {
        loading: data.loading,
        attractions: {
            count: data.attractions.count,
            items: data.attractions.items
        },
        fetchMore: () => {
            const { attractions, fetchMore, variables } = data;

            let queryVariables = JSON.parse(JSON.stringify(variables));
            queryVariables.pagination.skip = attractions.items.length;

            fetchMore({
                variables: queryVariables,
                updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
                    if (!fetchMoreResult) return previousResult;
                    return {
                        ...previousResult,
                        // Add the new matches data to the end of the old matches data.
                        attractions: {
                            __typename: previousResult.attractions.__typename,
                            ...previousResult.attractions.count,
                            items: [
                                ...previousResult.attractions.items,
                                ...fetchMoreResult.attractions.items,
                            ]
                        },
                    };
                },
            })
        },
        variables: data.variables
    };
};

const mapPropsToOptions = (props: any) => {
    let filter = buildSearchFilter(props);

    return {
        variables: filter,
        notifyOnNetworkStatusChange: true
    };
};

const buildSearchFilter = (props: any): any => {
    const { contentId } = props.match.params;
    let filterQuery: BedynamicVariables = {};

    filterQuery.pagination = {
        skip: 0,
        limit: 20
    };

    filterQuery.filter = filterQuery.filter ? filterQuery.filter : {};
    filterQuery.filter._AND = filterQuery.filter._AND ? filterQuery.filter._AND : Array<AttractionFilter>();

    let destinationFilter: AttractionFilter = {
        contentId: Number(contentId)
    };

    filterQuery.filter._AND.push(destinationFilter);

    return filterQuery;
};


var destinationComponent = graphql(Destinations_GQL, {
    props: mapResultsToProps,
    options: mapPropsToOptions
})(DestinationView);

export default withRouter(destinationComponent);


