import gql from 'graphql-tag';

const TypedDefs = gql`
  type Query {
        state: State
    }
    type State {
        searchString: string
    }
    type Mutation {
      updateSearchState(searchString : string) : State
    }
    type Mutation {
      updateGeoLocation(position : string) : State
    }
`;

export default TypedDefs;