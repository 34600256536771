import { Accordion, Card, ListGroup, Form, Nav } from "react-bootstrap";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { applyFilter, clearFilter } from "../../apollo/queries/AppQuery";
import { ATTRACTION_TYPES_GQL } from "../../queries/AttractionQuery";
import { LoadingResults } from "./LoadingResults";
import { BedynamicAttractionType } from "../../generated/BedynamicAttractionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faLandmark, IconDefinition, faShoppingBag, faTree, faTheaterMasks, faCircle, faCaretRight, faRunning, faFootballBall, faChevronDown, faCalendarAlt, faDirections, faMapPin, faCity, faHotel } from "@fortawesome/free-solid-svg-icons";

const FilterComponent = (props: any) => {
    const [applyFilters] = useMutation(applyFilter);
    const [clearFilters] = useMutation(clearFilter);
    const [selectedFilters, setSelectedFilters] = useState(Array<string>());

    const buildTopAttractionFilter = (props: any): any => {
        const { city, state, country, contentId } = props.params;

        let filterQuery: BedynamicAttractionType = {
            filter: {
                // address: {
                //     city: { _EQ: city.replace("_", " ") },
                //     state: { _EQ: state.replace("_", " ") },
                //     country: { _EQ: country.replace("_", " ") }
                // }
                destinationVenue: {
                    contentId: Number.parseInt(contentId)
                }
            }
        };

        return filterQuery;
    };


    const { loading, data }: any = useQuery(ATTRACTION_TYPES_GQL, {
        variables: buildTopAttractionFilter(props),
    });

    if (loading && !data) return <LoadingResults />;
    if (data === undefined && data.attractionTypes === undefined) return <LoadingResults />;

    var results: any = data.attractionTypes.filter((f: any) => f !== "Destination").map((x: any) => {
        return { isActive: false, venueType: x };
    });

    const applyVenueTypeFilter = (e: any, type: string, filter: any = ''): any => {

        if (selectedFilters.indexOf(filter.venueType) === -1) {
            selectedFilters.push(filter.venueType);
            filter.isActive = true;

            applyFilters({ variables: { contentType: type, venueTypes: JSON.parse(JSON.stringify(selectedFilters)) } });
        }
        else {
            //Remove from local store
            selectedFilters.splice(selectedFilters.indexOf(filter.venueType), 1);
            filter.isActive = false;
            applyFilters({ variables: { contentType: type, venueTypes: JSON.parse(JSON.stringify(selectedFilters)) } });
        }
    }

    const applyEventSearch = (filter: any = ''): any => {
        results.map((x: any) => x.isActive = false);
        if (selectedFilters.indexOf(filter) === -1) {
            setSelectedFilters([]);
            selectedFilters.push(filter);

            applyFilters({ variables: { contentType: filter, venueTypes: [] } });
        }
        else {
            //Remove from local store
            selectedFilters.splice(selectedFilters.indexOf(filter), 1);
            clearFilters({ variables: {} });
        }
    }

    const applyAllVenuesSearch = () => {

        setSelectedFilters([]);
        applyFilters({ variables: { contentType: "Venues", venueTypes: [] } });
    }

    const getIcons = (type: any): IconDefinition => {
        switch (type) {
            case "Attraction":
                return faLandmark;
            case "Neighborhoods":
                return faCity;
            case "Shopping":
                return faShoppingBag;
            case "Natural Attractions":
                return faTree;
            case "Accomodations":
                return faHotel;
            case "Food & Drink":
                return faUtensils;
            case "Special Event Venues":
                return faTheaterMasks;
            case "Sports":
                return faFootballBall;
            case "Tours":
                return faDirections;
            default:
                return faCaretRight;
        }
    }

    return (
        <Accordion defaultActiveKey="0">
            <Card style={{ borderBottom: '10px solid rgba(0, 0, 0, 0.125)' }}>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    <FontAwesomeIcon icon={faMapPin} className="fontIconColorPrimary" /> While you're there
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <ListGroup variant="flush">
                            <ListGroup.Item key="-1" action onClick={() => applyAllVenuesSearch()}>
                                <FontAwesomeIcon icon={faChevronDown} className="fontIconColorPrimary" /> All Attractions
                            </ListGroup.Item>
                            <Form>
                                {results.length > 0 &&
                                    results.map((venueTypeObj: any, index: number) => (
                                        <ListGroup.Item key={index}>
                                            <Form.Check custom={true} className="ml-3" type="checkbox" id={'chk' + index} key={index}
                                                checked={selectedFilters.indexOf(venueTypeObj.venueType) !== -1}
                                                onChange={(e: any) => applyVenueTypeFilter(e, "Venues", venueTypeObj)}
                                                label={<><FontAwesomeIcon icon={getIcons(venueTypeObj.venueType)} className="fontIconColorPrimary" /> {venueTypeObj.venueType}</>} />
                                        </ListGroup.Item>
                                    ))
                                }
                            </Form>
                            <ListGroup.Item key="-2" action onClick={() => applyEventSearch("Events")}>
                                <FontAwesomeIcon icon={faCalendarAlt} className="fontIconColorPrimary" /> Events
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );

};

export default FilterComponent;