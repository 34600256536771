import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "./AttractionDetailsView.scss";
import { graphql } from "react-apollo";
import { withRouter } from "react-router";
import { AttractionFilter } from "../../generated/globalTypes";
import { BedynamicVariables, Bedynamic_attractions_items, Bedynamic_attractions_items_images, Bedynamic_attractions_items_urls } from "../../generated/Bedynamic";
import { ATTRACTIONS_GQL } from "../../queries/AttractionQuery";
import { LoadingResults } from "../common/LoadingResults";
import AppHeader from "../header/AppHeader";


const AttractionDetailsView = (props: any) => {

    let windows: any = window;

    const { loading, attractions }: any = props;

    if (loading && !attractions) return <LoadingResults />;
    if (attractions === undefined) return <LoadingResults />;

    const attraction: Bedynamic_attractions_items = attractions.items[0];

    const loadScript = () => {
        var isFound: any = false;
        var scripts: any = document.getElementsByTagName("script")
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
                isFound = true;
            }
        }

        if (!isFound) {
            var dynamicScripts = ["https://widgets.skyscanner.net/widget-server/js/loader.js"];

            for (var i = 0; i < dynamicScripts.length; i++) {
                let node = document.createElement('script');
                node.src = dynamicScripts[i];
                node.type = 'text/javascript';
                node.async = false;
                node.charset = 'utf-8';
                document.getElementsByTagName('head')[0].appendChild(node);
            }

        }
    }
    loadScript();

    const getAddressTitle = (attraction: Bedynamic_attractions_items) => {
        if (attraction.cType == "Events" && attraction.venue != null && attraction.venue.address != null) {
            return `${attraction.name}, ${attraction.venue.address.country}`;
        }
        else if (attraction.cType == "Venues" && attraction.address != null) {
            return `${attraction.name}, ${attraction.address.country}`;
        }
    }
    const getAddressDisplay = (attraction: Bedynamic_attractions_items) => {
        if (attraction.cType == "Events" && attraction.venue != null && attraction.venue.address != null) {
            return `${attraction.venue.address.addressLine1}, 
            ${attraction.venue.address.city}, 
            ${attraction.venue.address.state}, 
            ${attraction.venue.address.country} - 
            ${attraction.venue.address.postalCode}`;
        }
        else {
            return `${attraction.address.addressLine1}, 
            ${attraction.address.city}, 
            ${attraction.address.state}, 
            ${attraction.address.country} - 
            ${attraction.address.postalCode}`;
        }
    }

    return (

        <Container fluid={true}>
            <Row>
                <Col md={12}>
                    <AppHeader />
                </Col>
            </Row>
            <Row style={{ marginTop: '2%' }}>
                <Col md={4}>
                    <h4>{getAddressTitle(attraction)}</h4>
                </Col>
                <Col md={8}>

                </Col>
            </Row>
            <Row className="mt-3">

                <Col md={4}>
                    {
                        <>
                            <div>
                                <p className="text-justify" dangerouslySetInnerHTML={{ __html: attraction.description }}></p>
                            </div>
                            <div>
                                <h6>Address</h6>
                                <span>{getAddressDisplay(attraction)}
                                </span>
                            </div>
                            <div className="mt-2">
                                {
                                    attraction.urls && attraction.urls.length > 0 &&
                                    attraction.urls.map((attractionUrl: Bedynamic_attractions_items_urls, index: number) => (
                                        <a key={index} className="mb-1 d-block" target="_blank" href={attractionUrl.urlPath}>{attractionUrl.urlPath}</a>
                                    ))
                                }
                            </div>
                            <div className="mt-2">
                                {attraction.tags.length > 0 &&
                                    attraction.tags.map((term: string, index: number) => (
                                        <span key={index} className="mb-1 btn btn-primary chipDisplay mr-2">{term}</span>
                                    ))
                                }
                                {attraction.categories.length > 0 &&
                                    attraction.categories.map((category: string, index: number) => (
                                        <span key={index} className="mb-1 btn btn-primary chipDisplay mr-2">{category}</span>
                                    ))
                                }
                                {attraction.classifications.length > 0 &&
                                    attraction.classifications.map((classification: string, index: number) => (
                                        <span key={index} className="mb-1 btn btn-primary chipDisplay mr-2">{classification}</span>
                                    ))
                                }
                            </div>
                            {/* <div className="mt-3">
                                <div className='widget' data-skyscanner-widget="LocationWidget"
                                    data-whitelabeldomain="whitelabeldemo.skyscanner.net"
                                    data-destination-coords={`${attraction.address.latitude},${attraction.address.longitude}`}
                                    data-destination-name={`'${attraction.address.city}'`}
                                    >
                                </div>
                            </div>
                            <div className="text-center mt-1">
                                <b>OR</b>
                            </div>
                            <div className="mt-3">
                                <h6>Book your flights at finger tip</h6>
                                <div
                                    data-skyscanner-widget="SearchWidget"
                                    data-locale="en-US"
                                    data-market="US"
                                    data-currency="USD"
                                    data-responsive="true"
                                    data-origin-geo-lookup="true"
                                    data-destination-name={`'${attraction.address.city}'`}
                                    data-enable-placeholders="true"
                                    data-powered-by-size="0.5"
                                    data-button-label="Book now!"
                                    data-params="fontColour:#000;buttonColour:#EFB301;buttonFontColour:#fff;"
                                ></div>
                                <script src="https://widgets.skyscanner.net/widget-server/js/loader.js" async></script>
                            </div> */}
                        </>
                    }
                </Col>

                <Col md={8}>
                    <Carousel fade={true} id="detailsCarousel">
                        {
                            attraction.images.filter( x => x.imageTypeText != "Thumbnail").map((image: Bedynamic_attractions_items_images, index: number) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={image.url}
                                        alt="First slide"
                                    />
                                    <Carousel.Caption>
                                        <h2>{attraction.name}</h2>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                </Col> </Row>
            <Row className="mt-3">
                <Col md={4}>
                </Col>
                <Col md={8}>
                    {/* <p className="text-justify" dangerouslySetInnerHTML={{ __html: attraction.description }}></p> */}
                </Col>
            </Row>
        </Container>);
}


const mapResultsToProps = ({ data }: any) => {
    if (!data.attractions)
        return {
            loading: data.loading,
        };

    return {
        loading: data.loading,
        attractions: {
            count: data.attractions.count,
            items: data.attractions.items
        },
        fetchMore: () => {
            const { attractions, fetchMore, variables } = data;

            let queryVariables = JSON.parse(JSON.stringify(variables));
            queryVariables.pagination.skip = attractions.items.length;

            fetchMore({
                variables: queryVariables,
                updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
                    if (!fetchMoreResult) return previousResult;
                    return {
                        ...previousResult,
                        // Add the new matches data to the end of the old matches data.
                        attractions: {
                            __typename: previousResult.attractions.__typename,
                            ...previousResult.attractions.count,
                            items: [
                                ...previousResult.attractions.items,
                                ...fetchMoreResult.attractions.items,
                            ]
                        },
                    };
                },
            })
        },
        variables: data.variables
    };
};

const mapPropsToOptions = (props: any) => {
    let filter = buildSearchFilter(props);

    return {
        variables: filter,
        notifyOnNetworkStatusChange: true
    };
};

const buildSearchFilter = (props: any): any => {
    const { contentId } = props.match.params;
    let filterQuery: BedynamicVariables = {};

    filterQuery.pagination = {
        skip: 0,
        limit: 20
    };

    filterQuery.filter = filterQuery.filter ? filterQuery.filter : {};
    filterQuery.filter._AND = filterQuery.filter._AND ? filterQuery.filter._AND : Array<AttractionFilter>();

    let destinationFilter: AttractionFilter = {
        contentId: Number(contentId)
    };

    filterQuery.filter._AND.push(destinationFilter);

    return filterQuery;
};


var destinationComponent = graphql(ATTRACTIONS_GQL, {
    props: mapResultsToProps,
    options: mapPropsToOptions
})(AttractionDetailsView);

export default withRouter(destinationComponent);

