import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./AttractionView.scss";
import { Query } from "react-apollo";
import AttractionResultsContainer from "./AttractionResultContainer";
import { withRouter } from "react-router";
import ContentHeader from "../../header/ContentHeader";
import { getAppState } from "../../../apollo/queries/AppQuery";
import LeftComponent from "../../common/LeftPanelComponent";
import HeaderSearch from "../../header/HeaderSearch";
import AppHeader from "../../header/AppHeader";


const AttractionView = (props: any) => {

    return (
        <Container fluid={true}>
            <AppHeader />
            <Row>
                <Col md={12} className="text-center">
                    {/* <ContentHeader /> */}
                    <HeaderSearch />
                </Col>
            </Row>
            <Row className="mt-1">
                <Query query={getAppState}>
                    {({ loading, data }: any) => {
                        if (loading) return <></>
                        return (
                            <>
                                <Col md={3} className="mt-2">
                                    <div>
                                        <LeftComponent localeState={data.state} params={props.match.params} />
                                    </div>
                                </Col>
                                <Col md={8} className="mt-1">
                                    <div>
                                        <AttractionResultsContainer localeState={data.state} params={props.match.params} />
                                    </div>
                                </Col>
                            </>
                        )
                    }}
                </Query>
            </Row>
        </Container>);
}


export default withRouter(AttractionView);

