import React from "react";
import { BedynamicVariables } from "../../generated/Bedynamic";
import { useQuery } from "react-apollo";
import { Destinations_GQL } from "../../queries/AttractionQuery";
import { CardColumns, Card, CardDeck } from "react-bootstrap";
import { LoadingResults } from "../common/LoadingResults";
import "./TopAttractions.scss";
import { BedynamicDestinations_attractions_items, BedynamicDestinations_attractions_items_images } from "../../generated/BedynamicDestinations";
import { AttractionFilter } from "../../generated/globalTypes";

const TopRestaurants: any = (props: any) => {
    const { city, state, country, contentId } = props.params;

    const buildTopAttractionFilter = (props: any): any => {
        const { city, state, country, contentId } = props.params;
        let filterQuery: BedynamicVariables = {};

        filterQuery.pagination = {
            skip: 0,
            limit: 4
        };

        filterQuery.filter = filterQuery.filter ? filterQuery.filter : {};
        filterQuery.filter._AND = filterQuery.filter._AND ? filterQuery.filter._AND : Array<AttractionFilter>();

        let destinationFilter: AttractionFilter = {
            venueType: {
                name: {
                    _EQ: "Food & Drink"
                }
            },
            destinationVenue: {
                // city: { _EQ: city.replace("_", " ") },
                // state: { _EQ: state.replace("_", " ") },
                // country: { _EQ: country.replace("_", " ") }
                contentId: Number.parseInt(contentId)
            }
        };

        filterQuery.filter._AND.push(destinationFilter);

        return filterQuery;
    };

    const { loading, data }: any = useQuery(
        Destinations_GQL,
        {
            variables: buildTopAttractionFilter(props),
            notifyOnNetworkStatusChange: true,
        },
    );

    if (loading && !data) return <LoadingResults />;
    if (data === undefined) return <LoadingResults />;

    const navigateToDetails = (attraction: BedynamicDestinations_attractions_items) => props.history.push({
        pathname: `/view/${country.toLowerCase()}/${state.toLowerCase()}/${city.toLowerCase().trim().replace(/\s/g, "_")}/${contentId}`
    });

    const getImageUrl = (attraction: BedynamicDestinations_attractions_items) => {
        if (attraction.images.length == 0) return "http://placehold.it/180x180";

        let primaryImage: BedynamicDestinations_attractions_items_images[] = attraction.images.filter((x: BedynamicDestinations_attractions_items_images) => x.imageTypeText === "Primary");
        if (primaryImage.length > 0) return primaryImage[0].url;

        return attraction.images[0].url;
    }

    return (
        <> <CardDeck>
            {
                data.attractions.items.length > 0 &&
                data.attractions.items.map((item: BedynamicDestinations_attractions_items, index: number) => (
                    <Card key={index} onClick={() => navigateToDetails(item)} text={'white'}>
                        {/* <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>
                                {item.address.city}
                            </Card.Text>
                        </Card.Body> */}
                        <Card.Img variant="bottom" src={getImageUrl(item)} />
                        <Card.ImgOverlay>
                            <Card.Title>{item.name}</Card.Title>
                            {/* <Card.Text>
                                {item.address.city}
                            </Card.Text> */}
                        </Card.ImgOverlay>
                    </Card>
                ))
            }
        </CardDeck>
        </>
    );
}
export default TopRestaurants;