import { useEffect, useState, useCallback, useRef } from "react";

export const useGoogleMap = (apiKey: any) => {
    const [googleMap, setGoogleMap] = useState(null);
    useEffect(() => {
        // GoogleMapsApiLoader({ apiKey }).then((google: any) => {
        //     setGoogleMap(google);
        // });
        const onLoad = () => {
            let google: any = window.google;
            setGoogleMap(google);
        }

        if (!window.google) {
            const script = document.createElement(`script`)
            script.type = `text/javascript`
            script.src =
                `https://maps.google.com/maps/api/js?key=` +
                apiKey
            const headScript: any = document.getElementsByTagName(`script`)[0]
            headScript.parentNode.insertBefore(script, headScript)
            script.addEventListener(`load`, onLoad)
            return () => script.removeEventListener(`load`, onLoad)
        } else onLoad();
    }, []);
    return googleMap;
};

export const useMap = ({ googleMap, mapContainerRef, initialConfig }: any) => {
    const [map, setMap] = useState(null);
    useEffect(
        () => {
            if (!googleMap || !mapContainerRef.current) {
                return;
            }
            const map = new googleMap.maps.Map(
                mapContainerRef.current,
                initialConfig
            );
            setMap(map);
        },
        [googleMap, mapContainerRef]
    );

    return map;
};

export const useDrawMapMarkers = ({ markers, googleMap, map }: any) => {
    const markerObjectsRef: any = useRef({});
    useEffect(
        () => {
            if (!googleMap || !map) {
                return;
            }
            //const { Marker } = googleMap.maps;
            markers.map((marker: google.maps.Marker, i: any) => {
                if (markerObjectsRef.current[i]) {
                    return;
                }
                // const markerObj = new Marker({
                //     position: new google.maps.LatLng(Number(data.lat), Number(data.lng)),
                //     map,
                //     title: data.title
                // });
                markerObjectsRef.current[i] = marker;
            });
        },
        [markers, googleMap, map]
    );

    return markerObjectsRef;
};

export const useMarkerState = (initialMarkers: any) => {
    const [markers, setMarkers] = useState(initialMarkers);
    const addMarker = ({ lat, lng , title }: any) => {
        setMarkers([...markers, { lat, lng, title }]);
    };
    const clear = () => {
        setMarkers([]);
    }
    const initialize = (markers : any) => {
        setMarkers(markers);
    }
    return { 
        markers,
        addMarker,
        clear,
        initialize
    };
};

export const useMapClickEvent = ({ onClickMap, googleMap, map }: any) => {
    useEffect(
        () => {
            const listener = googleMap.maps.event.addListener(map, "click", (e: any) => {
                console.log(e);
                onClickMap({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng()
                });
            });
            return () => {
                googleMap.maps.event.removeListener(listener);
            };
        },
        [googleMap, map, onClickMap]
    );
};