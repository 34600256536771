import React, { useRef, useEffect } from 'react';
import { Bedynamic_attractions_items, Bedynamic_attractions_items_urls, Bedynamic_attractions_items_images } from '../../../generated/Bedynamic';
import ReadMore from '../../common/read-more/ReadMore';
import { Alert, Card, CardColumns, CardDeck, Image } from 'react-bootstrap';
import { LoadingResults } from '../../common/LoadingResults';
import { ResultView } from './AttractionResultContainer';

const styles: any = {
    attractionContainer: {
    },
    img: {
        display: 'block',
        maxHeight: '200px',
        width: '100%',
        height: 'auto'
    }
};

export const AttractionsListComponent = (props: any) => {
    const ref: any = useRef(null);

    useEffect(() => {
        ref.current.scrollTo(0, 0);
    }
    );


    useEffect(() => {
        const { loading }: any = props.info;

        if (loading) return;

        const handleScrolls = () => {
            if (
                (window.innerHeight + document.documentElement.scrollTop)
                >= (document.documentElement.scrollHeight - 1)
            ) {
                if (hasMorePages()) fetchMore();
            }
        };

        const hasMorePages = (): boolean => {
            const { attractions } = props.info;
            if (attractions.items.length >= attractions.count) return false;
            return true;
        }

        window.addEventListener('scroll', handleScrolls);

        // Specify how to clean up after this effect:
        return function cleanup() {
            window.removeEventListener('scroll', handleScrolls);
        };
    });


    const { loading, attractions, fetchMore }: any = props.info;

    //if (loading) return <LoadingResults />;

    const navigateToDetails = (attraction: Bedynamic_attractions_items) => {
        if (attraction.cType == "Events" && attraction.venue != null && attraction.venue.address != null) {
            if (attraction.venue.address == null || attraction.venue.address.country == null || attraction.venue.address.state == null || attraction.venue.address.city == null) return;
            window.open(`/details/${attraction.venue.address.country.toString().toLowerCase()}/${attraction.venue.address.state.toString().toLowerCase()}/${attraction.venue.address.city.toLowerCase().trim().replace(/\s/g, '_')}/${attraction.name.toLowerCase().trim().replace(/\s/g, "_")}/${attraction.contentId}`, "_blank");
        }
        else if (attraction.cType == "Venues" && attraction.address != null) {
            if (attraction.address == null || attraction.address.country == null || attraction.address.state == null || attraction.address.city == null) return;
            window.open(`/details/${attraction.address.country.toString().toLowerCase()}/${attraction.address.state.toString().toLowerCase()}/${attraction.address.city.toLowerCase().trim().replace(/\s/g, '_')}/${attraction.name.toLowerCase().trim().replace(/\s/g, "_")}/${attraction.contentId}`, "_blank");
        }
    }

    const getImageUrl = (attraction: Bedynamic_attractions_items) => {
        if (attraction.images.length == 0) return "http://placehold.it/180x180";

        let primaryImage: Bedynamic_attractions_items_images[] = attraction.images.filter((x: Bedynamic_attractions_items_images) => x.imageTypeText === "Primary");
        if (primaryImage.length > 0) return primaryImage[0].url;

        return attraction.images[0].url;
    }

    const getAddressTitle = (attraction: Bedynamic_attractions_items) => {
        if (attraction.cType == "Events" && attraction.venue != null && attraction.venue.address != null) {
            return `${attraction.venue.address.addressLine1} ${attraction.venue.address.addressLine2}`;
        }
        else if (attraction.cType == "Venues" && attraction.address != null) {
            return `${attraction.address.addressLine1} ${attraction.address.addressLine2}`;
        }
    }
    const getAddressDisplay = (attraction: Bedynamic_attractions_items) => {
        if (attraction.cType == "Events" && attraction.venue != null && attraction.venue.address != null) {
            return `${attraction.venue.address.addressLine1} ${attraction.venue.address.addressLine2}`;
        }
        else {
            return `${attraction.address.addressLine1} ${attraction.address.addressLine2}`;
        }
    }
    return (
        <div ref={ref} className="column">
            <div>
                {attractions && (attractions.items === null || attractions.items.length === 0) &&
                    <Alert variant="primary" className="text-center">
                        <span>No attractions found</span>
                    </Alert>
                }
            </div>
            <div data-testid="result" className="mb-5 mr-1 ml-1" style={styles.attractionContainer} >

                {attractions && attractions.items != null && ResultView[props.view] === ResultView[ResultView.list] && attractions.items.map((attraction: Bedynamic_attractions_items, index: number) => (
                    <Card className="mb-1" border="info" key={index}>
                        <Card.Header as="h6">
                            <span className="float-left" style={{ cursor: "pointer" }} onClick={() => navigateToDetails(attraction)}>{attraction.name}</span>
                            {attraction.distance &&
                                <span className="float-right">{attraction.distance.toFixed(2)} miles away</span>
                            }
                        </Card.Header>
                        <Card.Body>
                            <div className="text-justify col-md-4">
                                <img style={styles.img} src={getImageUrl(attraction)} className="rounded float-left" alt="..."></img>
                            </div>
                            <div className="d-flex col-md-8">
                                <Card.Text className="text-justify">
                                    <div style={{ marginBottom: '10px' }}>
                                        {attraction.description.length > 250 &&
                                            <ReadMore
                                                readMoreCharacterLimit="250"
                                                text={attraction.description}
                                                numberOfLines={4}
                                                lineHeight={1.4}
                                                showLessButton={true}
                                            />
                                        }
                                        {attraction.description.length <= 250 &&
                                            <span dangerouslySetInnerHTML={{ __html: attraction.description }} />
                                        }
                                    </div>
                                    {attraction.cType === "Events" && attraction.venue.address &&
                                        <span className="mb-1 d-block">{`${attraction.venue.address.addressLine1}, ${attraction.venue.address.city}, ${attraction.venue.address.state}, ${attraction.venue.address.country}, ${attraction.venue.address.postalCode}`}</span>
                                    }
                                    {attraction.cType === "Venues" && attraction.address &&
                                        <span className="mb-1 d-block">{`${attraction.address.addressLine1}, ${attraction.address.city}, ${attraction.address.state}, ${attraction.address.country}, ${attraction.address.postalCode}`}</span>
                                    }
                                    {attraction.tags.length > 0 &&
                                        attraction.tags.map((term: string, index: number) => (
                                            <span key={index} className="mb-1 btn btn-primary chipDisplay mr-2">{term}</span>
                                        ))
                                    }
                                    {attraction.categories.length > 0 &&
                                        attraction.categories.map((category: string, index: number) => (
                                            <span key={index} className="mb-1 btn btn-primary chipDisplay mr-2">{category}</span>
                                        ))
                                    }
                                    {attraction.classifications.length > 0 &&
                                        attraction.classifications.map((classification: string, index: number) => (
                                            <span key={index} className="mb-1 btn btn-primary chipDisplay mr-2">{classification}</span>
                                        ))
                                    }
                                    {attraction.cType === "Venues" && attraction.venueType &&
                                        <span className="mb-1 btn btn-primary chipDisplay mr-2">{`${attraction.venueType}`}</span>
                                    }
                                    {
                                        attraction.urls && attraction.urls.length > 0 &&
                                        attraction.urls.map((attractionUrl: Bedynamic_attractions_items_urls, index: number) => (
                                            <a key={index} className="mb-1 d-block" target="_blank" href={attractionUrl.urlPath}>{attractionUrl.urlPath}</a>
                                        ))
                                    }
                                </Card.Text>
                            </div>
                            {/* <div>
             {
                 attraction.urls && attraction.urls.length > 0 &&
                 attraction.urls.map((attractionUrl: Bedynamic_attractions_items_urls, index: number) => (
                     <a key={index} className="mb-1 " target="_blank" href={attractionUrl.urlPath}>Book</a>
                 ))
             }
         </div> */}
                        </Card.Body>
                    </Card>
                ))}

                {attractions && attractions.items != null && ResultView[props.view] === ResultView[ResultView.tile] &&
                    <CardDeck className="h-100">
                        {attractions.items.map((attraction: Bedynamic_attractions_items, index: number) => (
                            <Card key={index} style={{ cursor: "pointer" }} onClick={() => navigateToDetails(attraction)}>

                                <Card.Img variant="bottom" src={getImageUrl(attraction)} />
                                <Card.Body>
                                    <Card.Title title={attraction.name} className="textEllipsis">{attraction.name}</Card.Title>
                                    <Card.Text title={getAddressTitle(attraction)} className="textEllipsis">
                                        {getAddressDisplay(attraction)}<br />
                                        {attraction.distance !== null && attraction.distance !== 0 &&
                                            <b>{attraction.distance.toFixed(2)} miles away</b>
                                        }
                                        {attraction.distance !== null && attraction.distance === 0 &&
                                            <b>You are at the spot</b>
                                        }
                                    </Card.Text>
                                </Card.Body>
                                {/* <Card.ImgOverlay>
                                <Card.Title>{attraction.name}</Card.Title>
                                 <Card.Text>
                             {item.address.city}
                         </Card.Text> 
                            </Card.ImgOverlay> */}
                            </Card>
                        ))}
                    </CardDeck>
                }
            </div>

            <div className="align-items-center justify-content-center text-center">
                {loading &&
                    <LoadingResults />
                }
            </div>
        </div>
    );

}
