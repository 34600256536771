import gql from 'graphql-tag';

export const setSearchString = gql`
    mutation updateSearchState($searchString : string){
        updateSearchState(searchString : $searchString) @client
    }
`;
export const setGeoLocation = gql`
    mutation updateGeoLocation($position : any){
        updateGeoLocation(position : $position) @client
    }
`;
export const applyFilter = gql`
    mutation applyFilter($contentType : string, $venueTypes : any){
        applyFilter(contentType: $contentType, venueTypes : $venueTypes) @client
    }
`;
export const clearFilter = gql`
    mutation clearFilter($args : any){
        clearFilter(args : $args) @client
    }
`;
export const getAppState = gql`
    query {
                state @client 
    }
`;