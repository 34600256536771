import { getAppState } from "../queries/AppQuery";

const state = {
    searchString: undefined,
    position: {},
    venueTypes: [],
    contentType: undefined,
    categories: [],
    tags: [],
    __typename: 'State',
};

const Resolvers = {
    Query: {
        state: () => state
    },
    Mutation: {
        updateSearchState: (_: any, { searchString }: any, { cache }: any) => {
            const state: any = cache.readQuery({ query: getAppState });
            let newState: any = JSON.parse(JSON.stringify(state));
            newState.state.searchString = searchString === "" ? undefined : searchString;
            // Add the new todo to the cache
            cache.writeQuery({
                query: getAppState,
                data: { ...state, ...newState }
            });
        },
        updateGeoLocation: (_: any, { position }: any, { cache }: any) => {
            const state: any = cache.readQuery({ query: getAppState });
            let newState: any = JSON.parse(JSON.stringify(state));
            newState.state.position = position;
            // Add the new todo to the cache
            cache.writeQuery({
                query: getAppState,
                data: { ...state, ...newState }
            });
        },
        applyFilter: (_: any, { contentType, venueTypes }: any, { cache }: any) => {
            const state: any = cache.readQuery({ query: getAppState });
            let newState: any = JSON.parse(JSON.stringify(state));

            //Apply content type
            newState.state.contentType = contentType;

            //Update Venue type
            newState.state.venueTypes = venueTypes;

            // Add the new todo to the cache
            cache.writeQuery({
                query: getAppState,
                data: { ...state, ...newState }
            });
        },
        clearFilter: (_: any, args: any, { cache }: any) => {
            const state: any = cache.readQuery({ query: getAppState });
            let newState: any = JSON.parse(JSON.stringify(state));
            newState.state.venueTypes = [];
            newState.state.contentType = undefined;
            // Add the new todo to the cache
            cache.writeQuery({
                query: getAppState,
                data: { ...state, ...newState }
            });
        },
    },
};

export default Resolvers;