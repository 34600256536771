import gql from "graphql-tag";

export const ATTRACTIONS_GQL = gql`
query Bedynamic($pagination : PaginationArg, $filter : logicalFilterAttractionFilterAttractions , $search : TextSearchFilter, $near : NearMeFilterType) {
    attractions(pagination : $pagination, filter : $filter , search : $search, near : $near) {
      count
      items {
        contentId,
        cultureId,
        cType,
        publishedOn,
        name,
        description,
        venueType,
        overview,
        images {
          url
          title
          imageName
          imageTypeText
        }
        urls {
          urlTypeText
          urlPath
        },
        location{
          coordinates
        },
        distance,
        address {
          latitude
          longitude
          postalCode
          city
          state
          country
          addressLine1
          addressLine2
          addressLine3
    },
    venue {
      address {
        latitude
        longitude
        postalCode
        city
        state
        country
        addressLine1
        addressLine2
        addressLine3
      }
    },
    tags,
    categories,
    classifications
      }
    }
}
`;


export const ATTRACTION_TYPES_GQL = gql`
query BedynamicAttractionType($filter : AttractionTypeFilterInput)
{
  attractionTypes(filter : $filter )
}
`;

export const Destinations_GQL = gql`
query BedynamicDestinations($pagination : PaginationArg, $filter : logicalFilterAttractionFilterAttractions , $search : TextSearchFilter, $near : NearMeFilterType) {
    attractions(pagination : $pagination, filter : $filter , search : $search, near : $near) {
   items {
    contentId,    
    name,
    cType,
    images {
      url , 
      imageTypeText
    },
    description,
    address {
      latitude
      longitude
      city
      state
      country
    }
   }
  count
 }
}
`;