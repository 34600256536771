import { useMutation, graphql } from "react-apollo";
import { setSearchString } from "../../../apollo/queries/AppQuery";
import React, { useState } from "react";
import { MapApp } from "./AttractionMapView";
import { BedynamicVariables } from "../../../generated/Bedynamic";
import { ATTRACTIONS_GQL } from "../../../queries/AttractionQuery";
import { AttractionsListComponent } from "./AttractionsListView";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { LoadingResults } from "../../common/LoadingResults";
import { AttractionFilter } from "../../../generated/globalTypes";
import { faBorderAll, faThLarge, faList, faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export enum ResultView {
    tile,
    map,
    list
}

const AttractionResultsContainer = (props: any) => {

    let chipDiv: any;
    let chipDisplayStyle: string = "inline-block";
    const [updateSearch] = useMutation(setSearchString);
    const [localState, setLocaleState] = useState({ resultView: ResultView.tile });
    const { loading, attractions, fetchMore }: any = props;

    if (loading && !attractions) return <LoadingResults />;
    if (attractions === undefined) return <LoadingResults />;

    const closeSearch = () => {
        chipDiv.style.display = "none";
        clearSearch()
    }

    const clearSearch = () => {
        updateSearch({ variables: { searchString: undefined } });
    }

    const hasMorePages = (): boolean => {
        const { attractions } = props;
        if (attractions.items.length >= attractions.count) return false;
        return true;
    }

    const loadMore = ({ currentTarget }: any, onFetchMore: any) => {
        if (hasMorePages()) onFetchMore();
    };

    return (
        <div>
            <div>
                <div >
                    <div className="row">
                        <div className="col-xs-4 col-sm-4 col-md-4 float-left">
                            {
                                props.localeState.searchString &&
                                <div className="mb-3 mt-1" ref={node => { chipDiv = node }}>
                                    <span>Showing results for </span>
                                    <div className="chip" style={{ display: chipDisplayStyle }}>
                                        <span><b>{props.localeState.searchString}</b></span>
                                        <span className="closebtn" onClick={closeSearch}>&times;</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-xs-8 col-sm-8 col-md-8 pr-0">
                            {
                                attractions.items && attractions.items.length > 0 &&

                                <ButtonGroup className="float-right mb-1" aria-label="Toggle view">
                                    <Button onClick={(e: any) => setLocaleState({ resultView: ResultView.tile })} variant="info"><FontAwesomeIcon icon={faThLarge} /> Tile</Button>
                                    <Button onClick={(e: any) => setLocaleState({ resultView: ResultView.list })} variant="info"><FontAwesomeIcon icon={faList} /> List</Button>
                                    <Button onClick={(e: any) => setLocaleState({ resultView: ResultView.map })} variant="info"><FontAwesomeIcon icon={faMapMarked} /> Map</Button>
                                </ButtonGroup>
                            }
                            {
                                localState.resultView == ResultView.map && hasMorePages() && fetchMore &&
                                <Button variant="link" className="float-right" onClick={(e: any) => loadMore(e, fetchMore)}>Load more..</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {localState.resultView !== ResultView.map && <AttractionsListComponent info={props} view={localState.resultView} />}
                {localState.resultView === ResultView.map && <MapApp info={props} />}
            </div>
        </div>
    );
}


const mapResultsToProps = ({ data }: any) => {
    if (data.loading)
        return {
            loading: data.loading,
        };

    return {
        loading: data.loading,
        attractions: {
            count: data.attractions.count,
            items: data.attractions.items
        },
        fetchMore: () => {
            const { attractions, fetchMore, variables } = data;

            let queryVariables = JSON.parse(JSON.stringify(variables));
            queryVariables.pagination.skip = attractions.items.length;

            fetchMore({
                variables: queryVariables,
                updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
                    if (!fetchMoreResult) return previousResult;
                    return {
                        ...previousResult,
                        // Add the new matches data to the end of the old matches data.
                        attractions: {
                            __typename: previousResult.attractions.__typename,
                            ...previousResult.attractions.count,
                            items: [
                                ...previousResult.attractions.items,
                                ...fetchMoreResult.attractions.items,
                            ]
                        },
                    };
                },
            })
        },
        variables: data.variables
    };
};

const mapPropsToOptions = (props: any) => {
    let filter = buildSearchFilter(props);

    let filterQuery: any = {
        pagination: {
            skip: 0,
            limit: 40
        }
    };
    Object.keys(filter).forEach((key: string) => {
        filterQuery[key] = filter[key];
    });

    return {
        variables: filterQuery,
        notifyOnNetworkStatusChange: true
    };
};

const buildSearchFilter = (props: any): any => {
    let filterQuery: BedynamicVariables = {};

    const { city, state, country, contentId } = props.params;
    const { localeState } = props;

    filterQuery.filter = filterQuery.filter ? filterQuery.filter : {};
    filterQuery.filter._AND = filterQuery.filter._AND ? filterQuery.filter._AND : Array<AttractionFilter>();

    let attractionFilter: AttractionFilter = {};

    if (Object.keys(localeState.position).length > 0) {
        filterQuery = {
            near: {
                _Location: {
                    Latitude: localeState.position.latitude.toString(),
                    Longitude: localeState.position.longitude.toString(),
                    MaxDistance: 2000
                }
            }
        }

        if (filterQuery.near && filterQuery.near._Location) {
            if (localeState.searchString) {

                attractionFilter.name = {
                    _Contains: localeState.searchString
                };
            }
            if (localeState.venueTypes.length > 0) {

                attractionFilter.venueType = {
                    name: {
                        _IN: localeState.venueTypes
                    }
                }
            }

            if (Object.keys(attractionFilter).length > 0) {
                filterQuery.near._Location.Query = {
                    _OR: [attractionFilter]
                }
            }

        }

        return filterQuery;
    }

    if (city && country && state && contentId) {
        // attractionFilter.address = {
        //     city: { _EQ: city.replace("_", " ") },
        //     state: { _EQ: state.replace("_", " ") },
        //     country: { _EQ: country.replace("_", " ") }
        // }
        attractionFilter.destinationVenue = { contentId : Number.parseInt(contentId)}
    }

    if (localeState.venueTypes.length > 0) {

        attractionFilter.venueType = {
            name: {
                _IN: localeState.venueTypes
            }
        }
    }

    if (localeState.contentType) {
        attractionFilter.cType = localeState.contentType;
    }

    if (!filterQuery.near && localeState.searchString) {
        attractionFilter.search =
            {
                _Text: localeState.searchString
            };
    }

    filterQuery.filter._AND.push(attractionFilter);

    return filterQuery;
};

const AttractionResultsComponent = graphql(ATTRACTIONS_GQL, {
    props: mapResultsToProps,
    options: mapPropsToOptions,
})(AttractionResultsContainer);

export default AttractionResultsComponent;
