import React, { useRef } from "react";
import { useGoogleMap, useMap } from "../../common/google-maps/GoogleMapsHooks";
import { Bedynamic_attractions_items } from "../../../generated/Bedynamic";
import { LoadingResults } from "../../common/LoadingResults";
import MarkerClusterer from "@google/markerclustererplus";

const API_KEY = 'AIzaSyDi3tSUx8E0msqtIBoLgJJlX0Es75OEJBE';
const initialConfig = {
    zoom: 11,
    center: { lat: 47.612943, lng: -122.4821475 }
};
var markers: any = [];
var infoWindows: Array<google.maps.InfoWindow> = [];
var markerCluster: any;


const clearMarkers = () => {
    if (markers) {
        for (let index = 0; index < markers.length; index++) {
            const element = markers[index];
            element.setMap(null);

        }
        markers = [];
    }
    infoWindows = [];
}

const buildDynamicHTML = (attraction: Bedynamic_attractions_items): string => {
    if (attraction.cType == "Events") return "";
    let dynamicHTML = '';
    let dynamicHTMLContainerStart = '<div>';
    let dynamicHTMLContainerEnd = "</div>";

    const url = `/details/${attraction.address.country}/${attraction.address.state}/${attraction.address.city.trim().replace(/\s/g, '_')}/${attraction.name.trim().replace(/\s/g, "_")}/${attraction.contentId}`;

    let titleStr = `
    <div style="padding-bottom:10px;">
        <b>
            <a id="details" contentId="${attraction.contentId}" target="_blank" href="${url}">${attraction.name}</a>
        </b>
    </div>`;

    let descriptionContainerStart = `<div style="padding-bottom: 10px;">`;
    let descriptionContainerEnd = "</div>";
    // let imageStr = `
    // <div class="zoom" style="-webkit-flex: 25;-ms-flex: 25;flex: 25;height: 110px;width: 75px;">
    //   <img style="max-width:100%;max-height:100%;border-radius: 10px;" src="${guide.image}" />
    // </div>`;
    let descriptionStr = `<div style="-webkit-flex: 60;-ms-flex: 60;flex: 60;padding-left: 2%;text-align: justify;">${attraction.description}`;
    let descriptionEnd = "</div>";
    let addressStr = `
    <span>${attraction.address.addressLine1}</span>
    <br/>
    <span>${attraction.address.city}, ${attraction.address.state}, ${attraction.address.country}</span>`;


    dynamicHTML = dynamicHTML + dynamicHTMLContainerStart;
    //Title section
    dynamicHTML = dynamicHTML + titleStr;
    //Description & image section
    dynamicHTML = dynamicHTML + descriptionContainerStart;

    if (attraction.images != null && attraction.images.length > 0) {
        let imageStr = `
    <div class="zoom" style="-webkit-flex: 25;-ms-flex: 25;flex: 25;height: 110px;text-align: center;padding-bottom: 10px;">
      <img style="max-width:100%;max-height:100%;border-radius: 10px;" src="${attraction.images[0].url}" />
    </div>`;
        dynamicHTML = dynamicHTML + imageStr;
    }

    if (attraction.description.length >= 380) {
        descriptionStr = `<div style="-webkit-flex: 60;-ms-flex: 60;flex: 60;padding-left: 2%;text-align: justify;">${attraction.description.substring(0, 380)}...`;
    }

    // dynamicHTML = dynamicHTML + descriptionStr;
    // // if (guide.phone != undefined && guide.phone != null) {
    // //     dynamicHTML = dynamicHTML + phoneStr;
    // // }
    // dynamicHTML = dynamicHTML + descriptionEnd;
    // dynamicHTML = dynamicHTML + descriptionContainerEnd;
    //Address section
    dynamicHTML = dynamicHTML + addressStr;
    dynamicHTML = dynamicHTML + dynamicHTMLContainerEnd;

    return dynamicHTML;

}

const updateGoogleMapClusters = (map: google.maps.Map, markers: Array<google.maps.Marker>) => {
    markerCluster.clearMarkers();
    markerCluster.removeMarker(markers);
    markerCluster.addMarkers(markers);
}

const MapMarkers = ({ googleMap, map, attractions }: any) => {
    markerCluster = new MarkerClusterer(map, [], {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    });
    // Marker managed as state 
    //const { markers, addMarker, clear, initialize } = useMarkerState([]);
    //markers = buildMarkers(attractions);
    clearMarkers();
    // draw 
    const { Marker } = googleMap.maps;

    if (attractions.items.length > 0)
        map.setCenter(new google.maps.LatLng(Number(attractions.items[0].location.coordinates[1]), Number(attractions.items[0].location.coordinates[0])));

    attractions.items.forEach((attraction: Bedynamic_attractions_items) => {
        // Initialise the inforWindow
        var infoWindow = new google.maps.InfoWindow({
            content: buildDynamicHTML(attraction)
        });
        google.maps.event.addListener(infoWindow, 'domready', () => {
            const el: any = document.getElementById('details');
            el.addEventListener('click', () => { });
        });
        var marker = new Marker({
            position: new google.maps.LatLng(Number(attraction.location.coordinates[1]), Number(attraction.location.coordinates[0])),
            map,
            title: attraction.name
        });
        markers.push(marker);

        infoWindows.push(infoWindow);
        // Display our info window when the marker is clicked
        google.maps.event.addListener(marker, 'click', function () {
            infoWindows.forEach((info: google.maps.InfoWindow) => {
                info.close();
            });
            infoWindow.open(map, marker);
            map.setCenter(new google.maps.LatLng(Number(attraction.location.coordinates[0]), Number(attraction.location.coordinates[1])));
        });

        updateGoogleMapClusters(map, markers);

    });


    //markerObjectsRef = useDrawMapMarkers({ markers, googleMap, map });
    // Add click event 
    // useMapClickEvent({
    //     onClickMap: ({ lat, lng }: any) => {
    //         // addMarker({ lat, lng });
    //     },
    //     map,
    //     googleMap
    // });
    // This component does not return anything because it is a component only for hooks. 
    // If it is uncomfortable to return null, it is also possible to return `<script />`, `[]`, `" "`, etc. 
    return null;
};

export const MapApp = (props: any) => {
    const googleMap = useGoogleMap(API_KEY);
    const mapContainerRef = useRef(null);
    const map = useMap({ googleMap, mapContainerRef, initialConfig });

    const { loading, attractions }: any = props.info;

    if (!googleMap) return <LoadingResults />;

    return (
        <>
            <div style={{ height: '80vh', width: '100%' }} ref={mapContainerRef}>
            </div>
            {!map && <LoadingResults />}
            {loading && <LoadingResults />}
            {map && <MapMarkers googleMap={googleMap} map={map} attractions={attractions} />}
        </>
    );
};


