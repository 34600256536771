import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Carousel, Container, Row, Col, Card, CardColumns, CardGroup, CardDeck } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import AppHeader from "./header/AppHeader";
import { BedynamicVariables } from "../generated/Bedynamic";
import { Destinations_GQL } from "../queries/AttractionQuery";
import { LoadingResults } from "./common/LoadingResults";
import { graphql } from "react-apollo";
import { BedynamicDestinations_attractions_items, BedynamicDestinations_attractions_items_images } from "../generated/BedynamicDestinations";
import { AttractionFilter } from "../generated/globalTypes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';

const App = (props: any) => {
  const { loading, attractions }: any = props;

  if (loading && !attractions) return <LoadingResults />;
  if (attractions === undefined) return <LoadingResults />;

  const getImageUrl = (attraction: BedynamicDestinations_attractions_items) => {
    if (attraction.images.length == 0) return "http://placehold.it/180x180";

    let primaryImage: BedynamicDestinations_attractions_items_images[] = attraction.images.filter((x: BedynamicDestinations_attractions_items_images) => x.imageTypeText === "Primary");
    if (primaryImage.length > 0) return primaryImage[0].url;

    return attraction.images[0].url;
  
}

  let bannerImages: Array<string> = attractions.items.slice(0, 5).map((x: BedynamicDestinations_attractions_items) => {
    let imageUrl = getImageUrl(x);
    return { imageUrl: imageUrl, name: x.name, address: x.address, contentId: x.contentId };
  });

  const navigateToDetails = (attraction: BedynamicDestinations_attractions_items) => props.history.push({
    pathname: `destination/${attraction.address.country.toLowerCase()}/${attraction.address.state.toLowerCase()}/${attraction.address.city.toLowerCase().replace(" ", "_")}/${attraction.contentId}`
  });

  


  return (
    <Container>
      <Row>
        <Col md={12}>
          <AppHeader />
        </Col>
      </Row>
      <Row className="mt-2 text-center">
        <Col md={12}>
          <Carousel fade={true}>
            {
              bannerImages.length > 0 &&
              bannerImages.map((x: any, index: number) => (
                <Carousel.Item key={index}>
                  <img onClick={() => navigateToDetails(x)}
                    className="d-block w-100"
                    src={x.imageUrl}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h2>{x.name}</h2>
                  </Carousel.Caption>
                </Carousel.Item>
              ))
            }
          </Carousel>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <p className="text-justify">Our travel guides, which are curated by Travel + Leisure editors and a network of local correspondents, highlight the best hotels, restaurants, shops, bars, and things to do in the world's most exciting destinations</p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <div>
            <h3 className="float-left"><FontAwesomeIcon icon={faPlaneDeparture} className="fontIconColorPrimary" /> Top Destinations</h3>
            {/* <a className="float-right" href="#">See All</a> */}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <CardDeck id="homeCards">
            {attractions && attractions.items != null && attractions.items.map((attraction: BedynamicDestinations_attractions_items, index: number) => (
              <Card key={index} onClick={() => navigateToDetails(attraction)} text={'white'}>
                {/* <Card.Body>
                    <Card.Title>{attraction.name}</Card.Title>
                    <Card.Text>
                      {attraction.address.country}
                    </Card.Text>
                    
                  </Card.Body> */}
                <Card.Img variant="bottom" width="80px" height="200px" src={getImageUrl(attraction)} />
                <Card.ImgOverlay>
                  <Card.Title>{attraction.name}</Card.Title>
                </Card.ImgOverlay>
              </Card>
            ))}
          </CardDeck>
        </Col>
      </Row>
    </Container >
  );
}

const mapResultsToProps = ({ data }: any) => {
  if (!data.attractions)
    return {
      loading: data.loading,
    };

  return {
    loading: data.loading,
    attractions: {
      count: data.attractions.count,
      items: data.attractions.items
    },
    fetchMore: () => {
      const { attractions, fetchMore, variables } = data;

      let queryVariables = JSON.parse(JSON.stringify(variables));
      queryVariables.pagination.skip = attractions.items.length;

      fetchMore({
        variables: queryVariables,
        updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return previousResult;
          return {
            ...previousResult,
            // Add the new matches data to the end of the old matches data.
            attractions: {
              __typename: previousResult.attractions.__typename,
              ...previousResult.attractions.count,
              items: [
                ...previousResult.attractions.items,
                ...fetchMoreResult.attractions.items,
              ]
            },
          };
        },
      })
    },
    variables: data.variables
  };
};

const mapPropsToOptions = () => {
  let filter = buildSearchFilter();

  let filterQuery: any = {
    pagination: {
      skip: 0,
      limit: 20
    }
  };
  Object.keys(filter).forEach((key: string) => {
    filterQuery[key] = filter[key];
  });

  return {
    variables: filterQuery,
    notifyOnNetworkStatusChange: true
  };
};

const buildSearchFilter = (): any => {
  let filterQuery: BedynamicVariables = {};

  filterQuery.filter = filterQuery.filter ? filterQuery.filter : {};
  filterQuery.filter._AND = filterQuery.filter._AND ? filterQuery.filter._AND : Array<AttractionFilter>();

  let destinationFilter: AttractionFilter = {
    venueType: {
      name: {
        _EQ: "Destination"
      }
    }
  };

  filterQuery.filter._AND.push(destinationFilter);

  return filterQuery;
};

const AppComponent: any = graphql(Destinations_GQL, {
  props: mapResultsToProps,
  options: mapPropsToOptions,
})(App);


export default withRouter(AppComponent);

