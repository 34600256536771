import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import TypeDefs from './definations/TypeDefs';
import Resolvers from './resolvers/Resolvers';
import { HttpLink } from 'apollo-link-http';


export function createClient(): ApolloClient<NormalizedCacheObject> {
    const BEDYNAMIC_BASE_URL = "https://dgcontentservice.azurewebsites.net/graphql";
   //const BEDYNAMIC_BASE_URL = "http://localhost:4000/graphql";
    const httpLink = new HttpLink({
        uri: BEDYNAMIC_BASE_URL
    });

    const client = new ApolloClient({
        link: httpLink,
        cache : new InMemoryCache(),
        typeDefs: TypeDefs,
        resolvers: Resolvers,
    });
    
        return client;
}
