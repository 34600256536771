import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { createClient } from "./apollo/Apollo";
import "@fortawesome/fontawesome-free/css/all.css";
import AttractionDetailsView from "./components/attraction/AttractionDetailsView";
import DestinationView from "./components/attraction/DestinationView";
import AttractionView from "./components/attraction/AttractionView/AttractionView";
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fab);

export const apolloClient = createClient();

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <App />
        </Route>
        <Route path="/destination/:country/:state/:city/:contentId">
          <DestinationView />
        </Route>
        <Route path="/view/:country/:state/:city/:contentId">
          <AttractionView />
        </Route>
        <Route path="/details/:country/:state/:city/:name/:contentId">
          <AttractionDetailsView />
        </Route>
      </Switch>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
