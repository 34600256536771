import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router";
import "./AppHeader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
class AppHeader extends Component<RouteComponentProps> {

  render() {

    return (
      <Navbar bg="light" expand="lg" fixed="top">
        <Navbar.Brand>
          <Link to="/"><img src="/logo.png" style={{ width: "150px" }} alt="logo" /></Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            {/* <div className="searchContainer">
              <input type="text" placeholder="Explore Destinations..." />
              <div className="search"></div>
            </div> */}
          <Link to="/"><FontAwesomeIcon icon={faHome} className="fontIconColorPrimary" />&nbsp;Home</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(AppHeader);



