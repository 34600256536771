import { useMutation } from "react-apollo";
import { setSearchString, setGeoLocation } from "../../apollo/queries/AppQuery";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import NearComponent from "../attraction/NearComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCompass, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "./HeaderSearch.scss";

const defaultSettings = {
    enableHighAccuracy: false,
    timeout: Infinity,
    maximumAge: 0,
};

const HeaderSearch = () => {
    let searchString: any;
    const [updateSearch] = useMutation(setSearchString);
    const [position, setPosition] = useState({});
    const [error, setError] = useState<any>(null);
    const [updateGeoLocation] = useMutation(setGeoLocation);

    const handleClick = (event: any) => {
        let localSearch: any = searchString.value;
        event.preventDefault();
        updateSearch({ variables: { searchString: localSearch } });
        searchString.value = '';
    }

    const updateGeoState = (geo: any) => {
        updateGeoLocation({ variables: { position: geo } });
    }

    const onUserLocationChange = ({ coords, timestamp }: any) => {
        let geo = {
            latitude: coords.latitude,
            longitude: coords.longitude
        };
        setPosition(geo);
        updateGeoState(geo);
    };

    const onError = (error: any) => {
        setError(error.message);
    };

    const handleDetectMe = () => {
        const geo = navigator.geolocation;
        if (!geo) {
            setError("Geolocation is not supported");
            return;
        }
        geo.getCurrentPosition(onUserLocationChange, onError, defaultSettings);
        //autocompleteInput.value = "Showing near by spots";
    }

    const handleClearLocation = () => {
        setPosition({});
        updateGeoState({});
        // autocompleteInput.value = "";
    }

    return (
        <div className="w-100">
            {/* <NearComponent /> */}
            <Form className="d-inline-flex p-2 searchBar" onSubmit={handleClick}>
                <div className="input-group">
                    <div className="input-group-prepend">
                        {Object.keys(position).length === 0 &&
                            <button className="btn btn-info" type="button" onClick={handleDetectMe}>
                                <FontAwesomeIcon icon={faCompass} />&nbsp;Nearby
                    </button>
                        }
                        {Object.keys(position).length > 0 &&
                            <button className="btn btn-info" type="button" onClick={handleClearLocation}>
                                Showing Nearby <FontAwesomeIcon icon={faTimesCircle} />&nbsp;
                    </button>
                        }
                    </div>
                    <input type="search" ref={node => { searchString = node }} className="form-control" placeholder="What are you looking for ?" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                        <Button variant="info" onClick={handleClick}><FontAwesomeIcon icon={faSearch} /> </Button>
                    </div>
                </div>
            </Form>
        </div>

    );
}

export default HeaderSearch;