import React, { Component } from "react";
import FilterComponent from "./FilterComponent";

const LeftComponent = (props: any) => {
  const { localeState, params } = props;
  return (
    <FilterComponent localeState={localeState} params={params}></FilterComponent>
  );
}

export default LeftComponent;
